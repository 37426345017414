.my-okrs-page {
    padding: 20px;
  }
  
  .left-column {
    flex: 3;
    margin-left: 15%;
    margin-right: 10%;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
  }

  .right-column {
    flex: 1;
    margin-top: 1%;
    margin-left: -7%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Aligns items at the top */
    gap: 20px; /* Add gap between the sections */
    margin-right: 5%;
  }
  
  .top-right {
    margin-bottom: 20px; /* Reduce margin to control spacing */
  }
  
  .bottom-right {
    margin-top: 20px; /* Adjust margin to reduce space */
  }
  

  .okr-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .my-okrtile {
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #ddd;
    text-align: left;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}
  
.my-okrtile:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }